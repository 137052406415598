import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { getSeasonalAmounts } from "../../../methods/financialHelpers"
import { getPendingPayment } from "@/methods/paymentPendingUpdates"
import {
  PaymentMethodsStatus,
  PaymentMethodDefaultStatus,
} from "@/constants.ts"

dayjs.extend(isBetween)
dayjs.extend(advancedFormat)

export default {
  getPendingPayment(state, getters, rootState, rootGetters) {
    if (!rootGetters.transactions) return null
    return getPendingPayment(
      rootState.user.customer.id,
      rootGetters.transactions
    )
  },
  hasSeasonalPaymentPlan(state, getters) {
    return !!getters.currentPaymentSchedule?.seasonalPaymentFl
  },

  paymentsForBill(state, getters, rootState, rootGetters) {
    return (bill) => {
      const from = dayjs(bill.periodFrom),
        to = dayjs(bill.periodTo)
      return rootGetters.payments.filter((payment) =>
        dayjs(payment.acceptedDttm).isBetween(from, to, null, "[]")
      )
    }
  },

  lastPayment(state, getters, rootState, rootGetters) {
    const sortedPayments = rootGetters.payments.sort(paymentsDecending)
    return sortedPayments[0]
  },

  /**
   * Returns current payment schedule or debit if customer is on variable
   * Direct Debit.
   *
   * @returns {Object|undefined} - The current payment schedule
   * or { amount: number }.
   */
  nextPayment(state, getters, rootState, rootGetters) {
    if (!getters.hasDirectDebit) return undefined
    const balance = rootGetters.account.balance
    const isInDebit = balance > 0

    if (getters.hasVariableDirectDebit) {
      return isInDebit ? { amount: balance } : undefined
    }

    return getters.currentPaymentSchedule
  },

  hasLastPayment(state, getters) {
    return getters.lastPayment?.amount && getters.lastPayment?.acceptedDttm
  },

  /**
   * This getter returns current and future payment schedules.
   * The logic below handles the following scenarios:
   * Given current date of 6th October 2021
   * - The next payment will be made for the 'current' period:
   *   [{ fromDt: "2020-12-01", toDt: "2021-12-16", nextPaymentDt: "2021-10-13" }]
   * - Payment for the 'current' period has already been made, so the next period will be returned:
   *   [
   *     { fromDt: "2020-12-01", toDt: "2021-10-12", nextPaymentDt: "2021-10-04" },  // current period, payment made
   *     { fromDt: "2021-10-13", nextPaymentDt: "2021-11-04" } // future period, next payment
   *   ]
   *
   * @returns {Array} - The current and future payment schedules.
   */
  currentAndFuturePaymentSchedules(state, getters, rootState, rootGetters) {
    if (!rootGetters.payment_periods) return []
    const today = dayjs().startOf("day").hour(12) // was matching old and new agrement on day of start of new agreemnt

    const currentAndFuturePaymentSchedules = rootGetters.payment_periods.filter(
      (pp) => {
        const nextPaymentDt = dayjs(pp.nextPaymentDt)

        const isCurrent = today.isBetween(
          dayjs(pp.fromDt),
          pp.toDt ? dayjs(pp.toDt).endOf("day") : dayjs().endOf("day")
        )
        const isPaid = nextPaymentDt.isBefore(dayjs().startOf("day"))
        const isOpen =
          !pp.toDt ||
          nextPaymentDt.isBetween(today, dayjs(pp.toDt), "day", "(]")
        const isPending = dayjs(pp.fromDt).isAfter(dayjs().endOf("day"))

        if (!isOpen || isPaid) return false
        return isCurrent || isPending
      }
    )
    return currentAndFuturePaymentSchedules.sort(
      (a, b) => dayjs(a.fromDt).unix() - dayjs(b.fromDt).unix()
    )
  },

  /**
   * This getter returns payment schedule for the next payment
   *
   * @returns {Object|undefined} - The current payment schedule.
   */
  currentPaymentSchedule(state, getters) {
    const paymentSchedule = getters.currentAndFuturePaymentSchedules[0]
    if (!paymentSchedule) return undefined
    return formatPaymentSchedule(paymentSchedule)
  },

  /**
   * This getter returns payment schedule that starts after the next payment
   *
   * @returns {Object|undefined} - The future payment schedule.
   */
  futurePaymentSchedule(state, getters) {
    const paymentSchedule = getters.currentAndFuturePaymentSchedules[1]
    if (!paymentSchedule) return undefined
    return formatPaymentSchedule(paymentSchedule)
  },

  /**
   * This getter returns the latest payment schedule.
   *
   * @returns {Object|undefined} - The latest payment schedule.
   */
  latestPaymentSchedule(state, getters) {
    if (!getters.currentAndFuturePaymentSchedules.length) return undefined
    const lastIndex = getters.currentAndFuturePaymentSchedules.length - 1
    const paymentSchedule = getters.currentAndFuturePaymentSchedules[lastIndex]
    return formatPaymentSchedule(paymentSchedule)
  },

  hasVariableDirectDebit(state, getters) {
    return !getters.currentPaymentSchedule && getters.hasDirectDebit
  },

  latestPaymentMethod(state, getters, rootState, rootGetters) {
    return rootGetters.payment_methods.sort(
      (a, b) => dayjs(b.createdDttm).unix() - dayjs(a.createdDttm).unix()
    )[0]
  },

  hasDirectDebit(state, getters) {
    return !!(
      state.directDebitMandate ||
      getters.hasActiveDirectDebit ||
      state.hasPendingDirectDebit
    )
  },

  hasActiveDirectDebit(state, getters, rootState, rootGetters) {
    return rootGetters.payment_methods.some((method) => {
      return (
        method.status === PaymentMethodsStatus.Active &&
        method.defaultStatus === PaymentMethodDefaultStatus.Default &&
        method.paymentMethodType.includes("Direct Debit")
      )
    })
  },

  hasPendingDirectDebit(state, getters, rootState, rootGetters) {
    if (state.directDebit?.pending) return true // DD added in this sessions
    const pendingStatuses = [
      PaymentMethodsStatus.RequestingActivation,
      PaymentMethodsStatus.CoolingOff,
      PaymentMethodsStatus.Pending,
    ]
    return rootGetters.payment_methods.some((method) => {
      return (
        (pendingStatuses.includes(method.status) ||
          method.defaultStatus === PaymentMethodDefaultStatus.Pending) &&
        method.paymentMethodType.includes("Direct Debit")
      )
    })
  },

  hasCancelledDirectDebit(state, getters) {
    const method = getters.latestPaymentMethod
    if (!method) return false

    const cancelledStatuses = [
      PaymentMethodsStatus.PendingCancellation,
      PaymentMethodsStatus.RequestingCancellation,
      PaymentMethodsStatus.FailedCancellation,
      PaymentMethodsStatus.Cancelled,
    ]

    return (
      cancelledStatuses.includes(method.status) &&
      method.paymentMethodType.includes("Direct Debit")
    )
  },

  hasFuturePaymentDateChange(state, getters) {
    if (!getters.futurePaymentSchedule || !getters.currentPaymentSchedule) {
      return false
    }

    return (
      getters.currentPaymentSchedule.nextPaymentDate.date() !==
      getters.futurePaymentSchedule.nextPaymentDate.date()
    )
  },

  hasFuturePaymentAmountChange(state, getters) {
    return !!(
      getters.futurePaymentSchedule &&
      getters.currentPaymentSchedule?.amount !==
        getters.futurePaymentSchedule?.amount
    )
  },

  hasFuturePaymentTypeChange(state, getters) {
    return !!(
      getters.futurePaymentSchedule &&
      getters.currentPaymentSchedule?.seasonalPaymentFl !==
        getters.futurePaymentSchedule?.seasonalPaymentFl
    )
  },

  hasTooHighDebtToRenewOnFutureTariff(state, getters, rootState, rootGetters) {
    let paymentsConsidered = rootGetters.payments
    const debt = rootGetters.account.balance
    const paymentsSinceDt = dayjs().subtract(6, "months")

    if (getters.hasActiveDirectDebit) {
      paymentsConsidered = paymentsConsidered.filter(({ paymentMethodType }) =>
        paymentMethodType.includes("Direct Debit")
      )
    }

    const amountPaid = paymentsConsidered
      .filter((payment) =>
        dayjs(payment.postedDt || payment.createdDttm).isAfter(paymentsSinceDt)
      )
      .reduce((partialSum, payment) => partialSum + payment.amount, 0)
    return debt > amountPaid
  },
  isPaymentScheduleQueued(state, getters, rootState) {
    return !!(
      rootState.isPaymentScheduleQueuingEnabled && getters.futurePaymentSchedule
    )
  },
  canUpdatePaymentSchedule(state, getters, rootState, rootGetters) {
    return !!(
      !getters.isPaymentScheduleQueued &&
      rootState.isDirectDebitChangesEnabled &&
      !rootGetters["switchIn/isEveryMeterSwitchingIn"] &&
      getters.hasDirectDebit
    )
  },
  canUpdateDirectDebitMandate(state, getters, rootState, rootGetters) {
    return !!(
      rootState.isDirectDebitChangesEnabled &&
      !rootGetters["switchIn/isEveryMeterSwitchingIn"]
    )
  },
}

// Private functions
const paymentsDecending = (payment1, payment2) => {
  const p1 = dayjs(payment1.postedDt || payment1.createdDttm)
  const p2 = dayjs(payment2.postedDt || payment1.createdDttm)

  if (p1.isBefore(p2)) return 1
  if (p1.isAfter(p2)) return -1
  return 0
}

const formatPaymentSchedule = (paymentSchedule) => {
  const seasonalAmounts = getSeasonalAmounts(
    paymentSchedule.nextPaymentDt,
    paymentSchedule.amount,
    paymentSchedule.seasonalPaymentFl
  )

  return {
    ...paymentSchedule,
    nextPaymentDate: dayjs(paymentSchedule.nextPaymentDt),
    equalAmount: seasonalAmounts.equal,
    summerAmount: seasonalAmounts.summer,
    winterAmount: seasonalAmounts.winter,
  }
}
